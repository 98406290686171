import { graphql } from 'gatsby'
import React from 'react'
import Pagination from '../components/pagination/pagination'
import ListAll from '../components/list-all/list-all'
import SEO from '../components/seo/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PageListTemplateQuery($skip: Int = 0, $pageSize: Int = 4) {
    pages: allSanityPage(limit: $pageSize, skip: $skip) {
      edges {
        node {
          id
          title
          shortTitle
          slug {
            current
          }
          _rawMainImage
          excerpt
          categories {
            title
          }
        }
      }
      totalCount
    }

    site: sanitySiteSettings {
      keywords
      title
      description
    }
  }
`

const PageList = ({ data, pageContext }) => {
  const { site, pages } = data

  return (
    <Layout>
      <SEO title={`Projects | ${site?.title}`} description={site?.description} keywords={site?.keywords} />
      <ListAll items={pages.edges} title='Work & Projects' pageSize={pageContext.pageSize} currentPage={pageContext.currentPage || 1}/>

      <Pagination pageSize={pageContext.pageSize} totalCount={pages.totalCount} currentPage={pageContext.currentPage || 1} skip={pageContext.skip} base='/projects' />
    </Layout>
  )
}

export default PageList
